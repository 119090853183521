<template>
    <div style="width: 100%;height: calc(100vh - 130px);overflow-x: hidden;overflow-y: auto;">
        <div style="display: flex;margin-bottom: 5px;margin-top: 10px;">
            <div style="font-size: 20px;font-weight: bold;line-height: 30px; margin-left: 20px;">数据分析-台账查询</div>
        </div>
        <div>
            <el-row style="margin-top: 30px;">
                <el-col :span="24">
                    <el-form :model="searchForm" ref="searchForm" label-width="100px" class="demo-searchForm"
                        size="small">
                        <el-form-item label="专业分类" style="width: 80%;">

                            <el-checkbox-group v-model="searchForm.ApprovalMajor">
                                <el-checkbox-button v-for="item in ApprovalMajorList" :key="item.DicValue"
                                    :label="item.DicValue">{{
                                        item.DicName
                                    }}
                                </el-checkbox-button>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="搜索条件" style="width: 100%;">
                            <el-row>
                                <el-col :span="14">
                                    <div style="display: flex;align-items: center;width: 100%;">
                                        <el-input v-model="searchForm.TaskReviewCode" placeholder="请输入任务名称/报审单名称/报审单编号"
                                            style="width: 35%;min-width: 300px;margin-right: 10px;"></el-input>
                                        <div>
                                            <el-button @click="handleResetFilter">重置条件</el-button>
                                            <el-button type="primary" @click="handleSearch">查询数据</el-button>

                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="10" style="text-align: right;">
                                    <el-button type="primary" style="margin-right: 20px;"
                                        @click="openPrint(ApprovalType == '1' ? data : data2, ApprovalType)">导出 /
                                        打印</el-button>
                                    <!-- <el-button type="primary" style="margin-right: 20px;"
                                        @click="exportChange(ApprovalType == '1' ? '设备/材料台账' : '深化图纸台账')">导出</el-button>
                                    <el-button type="primary" style="margin-right: 20px;" @click="printChange('123')">
                                        打印</el-button> -->
                                </el-col>
                            </el-row>


                        </el-form-item>

                    </el-form>
                </el-col>

            </el-row>
            <div style="padding: 10px;">
                <el-tabs v-model="ApprovalType" @tab-click="changeTab">
                    <el-tab-pane label="设备/材料审核台帐" name="1"></el-tab-pane>
                    <el-tab-pane label="深化图纸审核台账" name="2"></el-tab-pane>
                </el-tabs>
                <el-table :data="ApprovalType === '1' ? data : data2" border :span-method="objectSpanMethod"
                    v-loading="loading" empty-text="暂无数据，请选择条件进行查询" ref="tableApproval">
                    <el-table-column prop="sort" label="序号" align="center" width="50">
                    </el-table-column>

                    <el-table-column prop="ApprovalCode" :label="ApprovalType === '1' ? '设备/材料报审单编号' : '深化图纸报审单编号'"
                        align="left" header-align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="ApprovalName" :label="ApprovalType === '1' ? '设备/材料报审单名称' : '深化图纸报审单名称'"
                        align="left" header-align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="TaskName" label="任务名称" align="left" header-align="center"
                        show-overflow-tooltip></el-table-column>

                    <el-table-column prop="ApprovalMajor" label="专业" align="center"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column prop="ApprovalBatch" label="报审批次" align="center" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.ApprovalBatch">第 <span
                                    style="color: red;font-weight: bold;font-size: 14px;">{{ scope.row.ApprovalBatch
                                    }}</span>
                                批次</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ApprovalDateTime" label="报审时间" align="center" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.CreateTime ? scope.row.CreateTime.substring(0, 10) : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="ApprovalDateTime" label="审批时间" align="center" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.SignUpdateTime ? scope.row.SignUpdateTime.substring(0, 10) : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="审核结果" align="center">
                        <el-table-column align="center" width="50" v-for="item in ColumnData"
                            :key="item.OrganizationKey" :label="item.OrganizationName" :prop="item.OrganizationKey">
                            <template slot-scope="scope">
                                <span
                                    :style="`color:${(scope.row[item.OrganizationKey] === 'B' || scope.row[item.OrganizationKey] === 'A') ? '#67C23A' : '#D1100B'};font-weight:bold;font-size:16px`">{{
                                        scope.row[item.OrganizationKey] }}</span>
                            </template>
                        </el-table-column>


                    </el-table-column>
                    <el-table-column prop="ApprovalARemark" label="备注" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <!-- <span v-if="scope.row.ApprovalStatus == 0" style="color: red;"> 未审核或暂存 </span>
                            <span v-else>{{ scope.row.ApprovalARemark }}</span> -->
                            <span style="font-size: 12px;color: #F56C6C;" v-if="scope.row.Status == 0">未提审
                            </span>
                            <span type="success" style="font-size: 12px;color: #67C23A;" v-if="scope.row.Status == 1">{{
                                item.ApprovalBatch == 1 ? '已提审' : '再提审' }}
                            </span>
                            <span style="font-size: 12px;color: #909399;" type="info" v-if="scope.row.Status == 2">审核中
                            </span>
                            <span style="font-size: 12px;color: #F56C6C;" type="danger" v-if="scope.row.Status == 3">已驳回
                            </span>
                            <span style="font-size: 12px;color: #67C23A;" type="success"
                                v-if="scope.row.Status == 4">已通过
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <RG_Print ref="RG_Print"></RG_Print>
    </div>
</template>
<script>
function MyDebounce(fn, duration = 100) {
    let timer = null;
    return (...arg) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.call(this, ...arg);
        }, duration)
    }
}
let that = null

import RG_Print from "./RG_Print";

export default {
    data() {
        return {
            searchForm: {
                ApprovalMajor: []
            },
            ApprovalType: '1',
            ApprovalMajorList: [],
            data: [],
            data2: [],
            ColumnData: [],
            loading: false,
        };
    },
    props: {
        projectId: {
            type: String,
            default: ''
        }
    },
    components: { RG_Print },
    methods: {
        openPrint(data, ApprovalType) {
            if (data.length > 0) {
                this.$refs.RG_Print.openDrawer(data, ApprovalType, false, this.ColumnData)
            } else {
                this.$message.warning('暂无数据，请查询数据后导出/打印')
            }

        },
        changeTab() {
            this.data = []
            this.data2 = []
            this.getDataList()
        },

        getColumnData() {
            this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetReview_OrganizationList", {}).then(res => {
                if (res.Success) {
                    this.ColumnData = res.Data || []
                } else {
                    this.$message.error('角色列表获取失败' + res.Msg)
                }
            })
        },
        getDataList() {
            this.loading = true
            this.$http.post("/Base_ProjectManage/Project_DataAnalysis/GetComprehensiveLedgerData", {
                "ProjectId": this.projectId,
                "ApprovalMajorKeyList": this.searchForm.ApprovalMajor,
                "ApprovalType": Number(this.ApprovalType),
                "Conditions": [
                    {
                        "Condition": "TaskReviewCode",
                        "Keyword": this.searchForm.TaskReviewCode
                    }
                ],

            }).then(res => {
                this.loading = false
                if (res.Success) {
                    if (res.Data.length > 0) {
                        let num = 1
                        res.Data.forEach((item, index) => {
                            if (index === 0) {
                                this.$set(item, 'sort', num)
                            } else {
                                if (item.ApprovalCode === res.Data[index - 1].ApprovalCode) {
                                    this.$set(item, 'sort', num)
                                } else {
                                    num++
                                    this.$set(item, 'sort', num)
                                }
                            }

                        })
                        if (this.ApprovalType === '1') {
                            this.data = res.Data || []
                        } else if (this.ApprovalType === '2') {
                            this.data2 = res.Data || []
                        }

                    } else {
                        this.data2 = []
                        this.data = []
                    }

                } else {
                    this.$message.error('数据获取失败' + res.Msg)
                }
            })
        },
        MergeCol(TableData, itemName, rowIndex) {
            var idName = TableData[rowIndex][itemName]; // 获取当前单元格的值
            // 合并单元格
            // id：属性名
            // rowIndex：行索引值

            if (rowIndex > 0) {
                // 判断是不是第一行
                // eslint-disable-next-line eqeqeq
                if (TableData[rowIndex][itemName] != TableData[rowIndex - 1][itemName]) {
                    // 先判断当前单元格的值是不是和上一行的值相等
                    var i = rowIndex;
                    var num = 0; // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
                    while (i < TableData.length) {
                        // 当索引值小于table的数组长度时，循环执行
                        if (TableData[i][itemName] === idName) {
                            // 判断循环的单元格的值是不是和当前行的值相等
                            i++; // 如果相等，则索引值加1
                            num++; // 合并的num计数加1
                        } else {
                            i = TableData.length; // 如果不相等，将索引值设置为table的数组长度，跳出循环
                        }
                    }


                    return {
                        rowspan: num, // 最终将合并的行数返回
                        colspan: 1,
                    };
                } else {
                    return {
                        rowspan: 0, // 如果相等，则将rowspan设置为0
                        colspan: 1,
                    };
                }
            } else {
                // 如果是第一行，则直接返回
                let i = rowIndex;
                let num = 0;
                while (i < TableData.length) {
                    // 当索引值小于table的数组长度时，循环执行
                    if (TableData[i][itemName] === idName) {
                        i++;
                        num++;
                    } else {
                        i = TableData.length;
                    }
                }


                return {
                    rowspan: num,
                    colspan: 1,
                };
            }

        },
        //表格合并
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            let data = this.ApprovalType === '1' ? this.data : this.data2

            if (columnIndex == 2) {
                return this.MergeCol(data, 'ApprovalName', rowIndex)
            } else if (columnIndex == 1) {
                return this.MergeCol(data, 'ApprovalCode', rowIndex)
            } else if (columnIndex == 3) {
                return this.MergeCol(data, 'TaskName', rowIndex)
            } else if (columnIndex == 0) {
                return this.MergeCol(data, 'sort', rowIndex)
            }

        },
        getApprovalMajorList() {
            this.$http
                .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
                    Condition: "DicKey",
                    Keyword: 'RG_ApprovalMajor',
                })
                .then(res => {
                    if (res.Success) {
                        this.ApprovalMajorList = res.Data.DictionaryDetailsList || []
                        console.log(this.ApprovalMajorList, 'this.ApprovalMajorList')
                    }
                })
        },
        handleResetFilter() {
            this.searchForm.ApprovalMajor = []
            this.searchForm.TaskReviewCode = ''
        },
        handleSearch: MyDebounce(function () {
            that.getDataList()
        }, 300),

    },
    mounted() {
        that = this
    },
    created() {
        console.log(11111111)
        this.getColumnData()
        this.getApprovalMajorList()
    },
    computed: {},
    watch: {},
};
</script>
<style lang='scss' scoped>
::v-deep .el-table th {
    background: #D1100B !important;
    color: #fff
}



::v-deep .el-form-item--small .el-form-item__content {
    margin-left: 120px !important;
}

::v-deep button:focus {
    outline: none;
}

::v-deep .el-checkbox-button.is-focus .el-checkbox-button__inner {
    border-color: #DCDFE6 !important;
}

::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-color: #D1100B;
}
</style>
